import axios from 'axios';


const instance = axios.create({
  baseURL: 'http://inventar-api.klimatskipromeni.mk/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json',
  }
});

export default instance;

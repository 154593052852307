import React from 'react';
import {useTranslation} from 'react-i18next';

const Title = (props) => {

    const {t, i18n} = useTranslation();

    return (
        <h1>{t('Title')}</h1>
    )
}

export default Title;
import React from 'react';

export default () => (
    <div className="text-dark">
        <div className={"row"}>
            <div className={"col-md-6"}>
                <strong>Copyright</strong> <a href="https://klimatskipromeni.mk/"
                                              className="text-dark">klimatskipromeni.mk</a> &copy; {(new Date().getFullYear())}
            </div>
            <div className={"col-md-6 text-right"}>
                <ul className="footer-socials list-inline">
                    <li>
                        <a className="tooltips" data-original-title="Gef" data-placement="top" data-toggle="tooltip"
                           href="http://www.thegef.org/gef/" title="">
                            <img className="img img-responsive footer-img" src="/images/gef.png"/>
                        </a>
                    </li>
                    <li>
                        <a className="tooltips" data-original-title="MZSPP" data-placement="top" data-toggle="tooltip"
                           href="http://www.moepp.gov.mk/" title="">
                            <img className="img img-responsive footer-img" src="/images/grb.png"/>
                        </a>
                    </li>
                    <li>
                        <a className="tooltips" data-original-title="UNDP" data-placement="top" data-toggle="tooltip"
                           href="http://www.undp.org.mk/" title="">
                            <img className="img img-responsive footer-img" src="/images/undp.png"/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);
